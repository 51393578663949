export const BILLING_PLANS = {
  BASE: 'Base',
  LAUNCHPAD: 'Launchpad',
  STARTER: 'Starter',
  GROWTH: 'Growth',
  GROWTH_PLUS: 'Growth +',
  SCALE: 'Scale',
  CUSTOM: 'Custom',
};

export const isLaunchpadPlanSubscribed = (planName) =>
  planName === BILLING_PLANS.LAUNCHPAD;

export const isBasePlanSubscribed = (planName) =>
  planName === BILLING_PLANS.BASE;

export const isStarterPlanSubscribed = (planName) =>
  planName === BILLING_PLANS.STARTER;

export const isGrowthPlanSubscribed = (planName) =>
  planName === BILLING_PLANS.GROWTH;

export const isGrowthPlusPlanSubscribed = (planName) =>
  planName === BILLING_PLANS.GROWTH_PLUS;

export const isCustomPlanSubscribed = (planName) =>
  planName === BILLING_PLANS.CUSTOM;

export const isScalePlanSubscribed = (planName) =>
  planName === BILLING_PLANS.SCALE;

export const customDemoUrl = (name, email, company, reason) => {
  const baseUrl = 'https://angellist.cal.com/startups-team/angellist-sales-app';
  return `${baseUrl}?name=${encodeURIComponent(
    name,
  )}&email=${encodeURIComponent(email)}&a1=${encodeURIComponent(
    company,
  )}&a3=${encodeURIComponent(reason)}`;
};
